//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImporterListTable from '@/modules/importer/components/importer-list-table.vue';
import { ImporterPermissions } from '@/modules/importer/importer-permissions';
import { mapGetters, mapActions } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/firestore'
import i18n  from '@/vueI18n';

export default {
  name: 'app-importer-list-page',

  components: {
    [ImporterListTable.name]: ImporterListTable,
  },

  async created() {
    // await firebase.firestore().collection('items').onSnapshot({ includeMetadataChanges: true }, async querySnapshot => {
    //    console.log(`Received query snapshot of size ${querySnapshot.size}`);
    //   this.doFilterInBackground();
    // }, err => {
    //    console.log(`Encountered error: ${err}`);
    // });
    this.unsubscribe = firebase.firestore().collection('items').onSnapshot(async querySnapshot => {
       console.log(`Received query snapshot of size ${querySnapshot.size}`);
      this.doFilterInBackground();
    }, err => {
       console.log(`Encountered error: ${err}`);
    });
  },
  async mounted() {
    await this.doFilter();
  },
  beforeDestroy(){
    if(this.unsubscribe) this.unsubscribe()
  },
  data() {
    return {
        unsubscribe:undefined
    };
  },
  computed: {
    ...mapGetters({
      rows: 'importer/list/rows',
      count: 'importer/list/count',
      loading: 'importer/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new ImporterPermissions(this.currentUser).create;
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.importation') 
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'importer/list/doFetch',
      doFetchInBackground: 'importer/list/doFetchInBackground',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = { status: 'inStock' }
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = { status: 'inStock' }
      return this.doFetchInBackground({
        filter,
      });
    },
  },
};
